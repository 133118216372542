import React, {useContext, useEffect, useRef, useState} from 'react';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
//import './PromoBanners.css';

// import required modules
import SwiperCore, {Pagination, Navigation, Keyboard} from 'swiper';

SwiperCore.use([Pagination, Navigation, Keyboard]);

function PromoBanners(props) {

  const {items} = props;

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const progressBarRef = useRef(null);

  return (
    <>
      {Array.isArray(items) && items.length > 0 && (
        <div className={`relative flex items-center w-full h-[540px]`}
             onClick={event => {
               event.stopPropagation();
             }}>
          <button type="button" ref={prevRef}
                  className="z-2 absolute transform-gpu flex bg-transparent border-0 h-10 w-10 items-center justify-between left-4"
                  style={{transform: 'translate(50% - 20px, 0)'}}>
            <Icon name={'slider-left-arrow'} className="h-[23px] w-[32px]"/>
          </button>
          <button type="button" ref={nextRef}
                  className="z-2 absolute transform-gpu flex bg-transparent border-0 h-10 w-10 items-center justify-between right-4"
                  style={{transform: 'translate(50% - 20px, 0)'}}>
            <Icon name={'slider-left-arrow'} className="h-[23px] w-[32px] rotate-180"/>
          </button>
          <Swiper
            loop={true}
            pagination={{
              type: 'progressbar',
              el: progressBarRef.current,
              progressbarFillClass: `absolute left-0 top-0 bg-blue-primary w-full h-full origin-top-left`,
            }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            spaceBetween={8}
            breakpoints={{
              320: {slidesPerView: 2.3},
              480: {slidesPerView: 2.5},
              600: {slidesPerView: 3},
              1024: {slidesPerView: 3.5},
              1280: {slidesPerView: 4},
            }}
            keyboard={{enabled: true}}
            className={`h-full`}>
            {items.map((banner, index) => (
              <SwiperSlide key={`promo-banner-desktop-${index}`}>
                <a href={banner.link} className={`block h-full`}>
                  <picture>
                    <source srcSet={banner.picture.webp} type="image/webp"/>
                    <source srcSet={banner.picture.avif} type="image/avif"/>
                    <img src={banner.picture.orig}
                         className='h-full w-full object-cover'
                         alt={banner.title}
                         width={'298px'}
                         height={'540px'}
                         loading={index > 3 ? 'lazy' : 'eager'}
                         style={{
                         height: '100%',
                         objectFit: 'cover',
                       }}/>
                  </picture>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <div id="promo-banners-desktop-pagination" ref={progressBarRef}
               className={`absolute bottom-[-11px] w-full h-1 !bg-grey-10 z-10`}/>
        </div>
      )}
    </>
  );
}

export default PromoBanners;